import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import Select from '@/components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255
      }
    },
    countryID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'common.country',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['country/isLoading'],
        options: vue.$store.getters['country/loadedItems'],
        required: true
      },
      rules: [
        {
          validator: validatorRequireNumber
        }
      ]
    },
    languageID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'common.language',
        valueField: 'id',
        labelField: 'description',
        loading: vue.$store.getters['language/isLoading'],
        options: vue.$store.getters['language/loadedItems'],
        required: true
      },
      rules: [
        {
          validator: validatorRequireNumber
        }
      ]
    },
    webshopURL: {
      type: MDinput,
      defaultValue: '',
      props: {
        type: 'url'
      }
    },
    webshopUsername: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    webshopPassword: {
      type: MDinput,
      defaultValue: '',
      props: {
        type: 'password',
        maxlength: 64
      },
      dynamicProps: form => ({
        caption: vue.isEdit ? 'user.newPassword' : 'common.password'
      })
    },
    // topCategoryOnWooCommerceID: {
    //   type: MDinput,
    //   props: {
    //     type: 'number',
    //     precision: 0
    //   }
    // },
    topCategoryOnWooCommerceName: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64
      }
    }
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
